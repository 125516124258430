import refreshToken from '@/plugins/refresh-token'
import { api } from '../main'
import { api as api2 } from '../mainNode'

const customerDetailOrder = async body => {
  await  refreshToken()
  const response = await api
    .post({
      path: 'customer/order',
      body,
    })
    .then(res => (res.data || []))
    .catch(err => {
      console.log('get customer order list err : ', err)

      return []
    })

  return response
}
const customerDetailService = async body => {
  ////await refreshToken()
  const response = await api2
    .post({
      path: 'customer/serve',
      body,
    })
    .then(res => (res.data || []))
    .catch(err => {
      console.log('get customer service useage list err : ', err)

      return []
    })

  return response
}
const customerDetailCourse = async body => {
  ////await refreshToken()
  const response = await api2
    .post({
      path: 'customer/serving',
      body,
    })
    .then(res => (res.data || []))
    .catch(err => {
      console.log('get customer course list err : ', err)

      return []
    })

  return response
}
const customerDetailCert = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'customer/cert',
      body,
    })
    .then(res => (res.data || []))
    .catch(err => {
      console.log('get customer cert list err : ', err)

      return []
    })

  return response
}

const customerDetailAppoint = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'customer/appoint',
      body,
    })
    .then(res => (res.data || []))
    .catch(err => {
      console.log('get customer appointment list err : ', err)

      return []
    })

  return response
}

export default {
  customerDetailOrder, customerDetailService, customerDetailCourse, customerDetailCert, customerDetailAppoint,
}
