<template>
  <v-data-table
    :headers="columns"
    :items="dataTableList"
    :options.sync="options"
    dense
    :loading="loading"
    fixed-header
    disable-sort
    hide-default-footer
    height="611"
    :loading-text="$t('data_loading_please_wait')"
    :no-data-text="loadingData?$t('data_loading_please_wait'):$t('no_information')"
  >
    <template v-slot:[`item.id`]="{ index }">
      {{ index + 1 }}
    </template>
    <template v-slot:[`item.order_id`]="{ item }">
      <router-link
        :to="{ name: 'order-detail', params: { id: item.order_id_pri } }"
        class="font-weight-medium text-decoration-none"
      >
        {{ item.order_id }}
      </router-link>
    </template>
    <template v-slot:[`item.order_create`]="{ item }">
      <ConverseDate :date="item.order_create" />
    </template>
    <template v-slot:[`item.orderpay_status_id`]="{ item }">
      <CustomBlock
        v-if="item.orderpay_status_id==null"
        :message="$t('deposit')"
        color="secondary"
        icon="mdi-cash-multiple"
      />
      <CustomBlock
        v-if="item.orderpay_status_id == 0"
        :message="$t('wait_pay')"
        color="warning"
        icon="mdi-alert-outline"
      />
      <CustomBlock
        v-if="item.orderpay_status_id == 1"
        :message="$t('complete_payment')"
        color="success"
        icon="mdi-check"
      />
      <CustomBlock
        v-if="item.orderpay_status_id == 2"
        :message="$t('owe')"
        color="error"
        icon="mdi-credit-card-outline"
      />
    </template>
    <template v-slot:[`item.order_totalpay`]="{ item }">
      {{ item.order_totalpay| formatPrice }}
    </template>
  </v-data-table>
</template>

<script>
import { ref, watch, onMounted } from '@vue/composition-api'
import { sumdate, formatPrice } from '@/plugins/filters'
import ConverseDate from '@/components/basicComponents/ConverseDate.vue'
import CustomBlock from '@/components/basicComponents/CustomBlock.vue'
import { i18n } from '@/plugins/i18n'

export default {
  components: {
    ConverseDate,
    CustomBlock,
  },
  filters: {
    sumdate,
    formatPrice,
  },
  props: {

    loadingData: {
      type: Boolean,
      default: false,
    },
    tableList: {
      type: Array,
      default: () => [],
    },

  },
  setup(props) {
    const loading = ref(true)
    const drug_equipment_list = ref([])
    const receiptList = ref([])
    const statusChekReceiptList = ref(false)

    const dataTableList = ref([])
    const options = ref({})
    const columns = ref([

      {
        text: '#', align: 'start', value: 'id', width: 30,
      },
      { text: i18n.t('receipt_code'), value: 'order_id' },
      { text: i18n.t('date'), value: 'order_create' },
      { text: i18n.t('status'), value: 'orderpay_status_id' },
      {
        text: i18n.t('price'), value: 'order_totalpay', width: 200, align: 'end',
      },

    ])

    onMounted(() => {
      dataTableList.value = props.tableList
      options.value.page = -1
      options.value.itemsPerPage = -1
      if (props.tableList.length > 0) {
        loading.value = false
      }
    })

    watch(() => props.tableList, value => {
      dataTableList.value = value
      options.value.page = -1
      options.value.itemsPerPage = -1
      loading.value = false
    })

    return {
      loading,
      drug_equipment_list,
      receiptList,
      statusChekReceiptList,
      dataTableList,
      options,
      columns,
    }
  },

}
</script>
