<template>
  <div>
    <v-data-table
      :headers="columns"
      :items="dataTableList"
      :options.sync="options"
      dense
      :loading="loadingData"
      fixed-header
      disable-sort
      hide-default-footer
      height="611"
      :loading-text="$t('data_loading_please_wait')"
      :no-data-text="loadingData?$t('data_loading_please_wait'):$t('no_information')"
    >
      <template v-slot:[`item.id`]="{ index }">
        {{ index+1 }}
      </template>
      <template v-slot:[`item.opd_code`]="{ item }">
        <router-link
          :to="{ name: 'ShowDetailCheck', params: { id: item.opd_id } }"
          class="font-weight-medium text-decoration-none"
        >
          {{ item.opd_code }}
        </router-link>
      </template>
      <template v-slot:[`item.opd_date`]="{ item }">
        <ConversDateTime :datetime="item.opd_date" />
      </template>
      <template v-slot:[`item.check`]="{ item }">
        <ol class="mb-0">
          <li
            v-for="(check, index) in item.check"
            :key="`${check.opdchecking_code} ${index}`"
          >
            {{ check.opdchecking_name }} ({{ check.opdchecking_code }})
          </li>
        </ol>
      </template>
      <template v-slot:[`item.course`]="{ item }">
        <ol class="mb-0">
          <li
            v-for="(course, index) in item.course"
            :key="`${course.orderdetail_name} ${index}`"
          >
            {{ course.orderdetail_name }} {{ course.orderdetail_amount }}
            {{ course.orderdetail_unit }}
          </li>
          <li
            v-for="(x, index) in item.drug"
            :key="`${index} ${x.orderdetail_name}`"
            class="primary--text"
          >
            {{ x.orderdetail_name }}
            {{ x.orderdetail_amount }}
            {{ x.orderdetail_unit }}
          </li>
        </ol>
      </template>
      <template v-slot:[`item.before`]="{ item }">
        <v-btn-toggle
          mandatory
          dense
          rounded
        >
          <v-btn
            color="error"
            small
            :disabled="!item.before"
            @click="previewImage(item.before, 1)"
          >
            <v-icon class="white--text me-1">
              {{ icons.mdiImageOutline }}
            </v-icon>
            {{ $t("before") }}
          </v-btn>
          <v-btn
            small
            color="primary"
            :disabled="!item.after"
            @click="previewImage(item.after, 2)"
          >
            <v-icon class="white--text me-1">
              {{ icons.mdiImageOutline }}
            </v-icon>
            {{ $t("after") }}
          </v-btn>
        </v-btn-toggle>
      </template>
      <template v-slot:[`item.face_chart`]="{ item }">
        <v-btn
          small
          :disabled="!item.face_chart"
          class="rounded-lg elevation-3"
          outlined
          color="primary"
          @click="previewImage(item.face_chart, 3)"
        >
          {{ $t("file") }}
        </v-btn>
      </template>
    </v-data-table>
    <v-dialog
      v-model="showImage"
      max-width="600"
    >
      <v-card>
        <v-card-title>
          <v-icon>mdi-image</v-icon>{{
            imageStatus == 1
              ? $t("picture_before")
              : imageStatus == 2
                ? $t("picture_after")
                : $t("document_important")
          }}
          <v-spacer></v-spacer>
          <v-btn
            icon
            color="error"
            @click="showImage=false"
          >
            <v-icon>{{ icons.mdiClose }}</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col
              v-for="(img, index) in urlImages"
              :key="index"
              cols="12"
              md="6"
              lg="4"
            >
              <v-img
                style="cursor:pointer"
                width="150"
                height="150"

                :src="img.opdupload_image"
                @click="showDetailimage(img.opdupload_image)"
              ></v-img>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions></v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogVisible"
      max-width="500"
    >
      <v-img
        contain
        :src="dialogImageUrl"
        alt=""
      >
      </v-img>
    </v-dialog>
  </div>
</template>

<script>
import { ref, onMounted, watch } from '@vue/composition-api'
import { mdiImageOutline } from '@mdi/js'
import { sumdate, formatPrice } from '@/plugins/filters'
import ConversDateTime from '@/components/basicComponents/ConversDateTime.vue'
import { i18n } from '@/plugins/i18n'

export default {
  components: {
    ConversDateTime,
  },
  filters: {
    sumdate,
    formatPrice,
  },
  props: {

    loadingData: {
      type: Boolean,
      default: false,
    },
    tableList: {
      type: Array,
      default: () => [],
    },
  },

  setup(props) {
    const loading = ref(true)
    const check_list = ref([])
    const dataTableList = ref([])
    const options = ref({})
    const columns = ref([
      {
        text: '#', align: 'start', value: 'id', width: 30,
      },
      {
        text: i18n.t('a_number'), align: 'start', value: 'opd_code', width: 110,
      },
      { text: i18n.t('date'), value: 'opd_date', width: 150 },
      { text: i18n.t('examination_list'), value: 'check', width: 200 },
      { text: i18n.t('list_treatment_use_services'), value: 'course', width: 500 },
      { text: i18n.t('doctor_inspector'), value: 'user_fullname', width: 250 },
      {
        text: i18n.t('photos_before_after'), value: 'before', width: 130, align: 'center',
      },
      {
        text: i18n.t('document_important'), value: 'face_chart', align: 'center', width: 250,
      },
    ])

    const dialogVisible = ref(false)
    const showImage = ref(false)
    const urlImages = ref([])
    const imageStatus = ref('')
    const dialogImageUrl = ref('')

    const showDetailimage = img => {
      dialogImageUrl.value = img
      dialogVisible.value = true
    }

    const previewImage = (url, status) => {
      urlImages.value = url
      imageStatus.value = status
      showImage.value = true
    }

    onMounted(() => {
      dataTableList.value = props.tableList
      options.value.page = -1
      options.value.itemsPerPage = -1
      loading.value = false
    })

    onMounted(() => {
      dataTableList.value = props.tableList
      options.value.page = 1
      options.value.itemsPerPage = -1
      loading.value = false
    })
    watch(() => props.tableList, value => {
      dataTableList.value = value
      options.value.page = -1
      options.value.itemsPerPage = -1
      loading.value = false
    })

    return {
      loading,
      check_list,
      dataTableList,
      columns,
      options,

      dialogVisible,
      showImage,
      urlImages,
      imageStatus,

      dialogImageUrl,
      showDetailimage,
      previewImage,

      icons: {
        mdiImageOutline,
      },
    }
  },

}
</script>
