<template>
  <v-data-table
    :headers="columns"
    :items="dataTableList"
    :options.sync="options"
    dense
    :loading="loadingData"
    fixed-header
    disable-sort
    hide-default-footer
    height="611"
    :loading-text="$t('data_loading_please_wait')"
    :no-data-text="loadingData?$t('data_loading_please_wait'):$t('no_information')"
  >
    <template v-slot:[`item.appoint_id_pri`]="{ index }">
      {{ index + 1 }}
    </template>
    <template v-slot:[`item.appoint_date`]="{ item }">
      <ConverseDate :date="item.appoint_date" />
    </template>
    <template v-slot:[`item.appoint_status_id`]="{ item }">
      <AppointmentStatus :type="+item.appoint_status_id" />
    </template>
    <template v-slot:[`item.appoint_status_line`]="{ item }">
      <AppointmentLineStatus :status="+item.appoint_status_line" /> / <AppointmentSmsStatus :status="+item.appoint_status_sms" />
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-tooltip
        color="#212121"
        top
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-if="!item.appoint_gid"
            x-small
            class="rounded-pill"
            outlined
            v-bind="attrs"
            color="error"
            :disabled="item.appoint_syn_id == '0'"
            v-on="on"
          >
            <v-icon>{{ icons.mdiGooglePlus }}</v-icon>
          </v-btn>
        </template>
        <span>{{
          $t("connect_google_calendar")
        }}</span>
      </v-tooltip>
      <v-tooltip
        color="#212121"
        top
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-if="item.appoint_gid"
            x-small
            class="rounded-pill"
            color="error"
            v-bind="attrs"
            :disabled="item.appoint_syn_id == '0'"
            v-on="on"
          >
            <v-icon>{{ icons.mdiGooglePlus }}</v-icon>
          </v-btn>
        </template>
        <span>{{
          $t("connect_google_calendar")
        }}</span>
      </v-tooltip>
      <v-tooltip
        color="#212121"
        top
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            icon
            color="success"
            v-on="on"
          >
            <v-icon>{{ icons.mdiCheck }}</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("manage") }}</span>
      </v-tooltip>
      <v-tooltip
        color="#212121"
        top
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            icon
            color="info"
            v-on="on"
          >
            <v-icon>{{ icons.mdiEyeOutline }}</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("detail") }}</span>
      </v-tooltip>
      <v-tooltip
        color="#212121"
        top
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            color="warning"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>{{ icons.mdiAlertOutline }}</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("pending") }}</span>
      </v-tooltip>
      <v-tooltip
        color="#212121"
        top
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            icon
            color="primary"
            v-on="on"
          >
            <v-icon>{{ icons.mdiPrinter }}</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("print") }}</span>
      </v-tooltip>
      <v-tooltip
        color="#212121"
        top
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            color="error"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("cancel") }}</span>
      </v-tooltip>
    </template>
  </v-data-table>
</template>

<script>
import { ref, onMounted, watch } from '@vue/composition-api'
import { sumdate, formatPrice } from '@/plugins/filters'
import AppointmentStatus from '@/components/basicComponents/AppointmentStatus.vue'
import AppointmentLineStatus from '@/components/basicComponents/AppointmentLineStatus.vue'
import AppointmentSmsStatus from '@/components/basicComponents/AppointmentSmsStatus.vue'
import ConverseDate from '@/components/basicComponents/ConverseDate.vue'
import { i18n } from '@/plugins/i18n'

export default {
  components: {
    ConverseDate,
    AppointmentStatus,
    AppointmentLineStatus,
    AppointmentSmsStatus,
  },
  filters: {
    sumdate,
    formatPrice,
  },
  props: {

    loadingData: {
      type: Boolean,
      default: false,
    },
    tableList: {
      type: Array,
      default: () => [],
    },
  },

  setup(props) {
    const loading = ref(true)
    const check_list = ref([])

    const dataTableList = ref([])
    const options = ref({})
    const columns = ref([
      {
        text: '#',
        value: 'appoint_id_pri',
        width: 30,
      },

      {
        text: i18n.t('topic'),
        value: 'appoint_topic',
        width: 200,
      },
      {
        text: i18n.t('date_of_appointment'),
        value: 'appoint_date',
        width: 120,
      },
      {
        text: i18n.t('time'),
        value: 'appoint_start',
        class: ' text-white',
        width: 120,
      },
      {
        text: i18n.t('doctor_appointment'),
        value: 'user_fullname',
        width: 200,
      },
      {
        text: i18n.t('status'),
        value: 'appoint_status_id',
        align: 'center',
        width: 150,
      },
      {
        text: `${i18n.t('status')} Line / SMS`,
        value: 'appoint_status_line',
        align: 'center',
        width: 170,
      },
    ])

    onMounted(() => {
      dataTableList.value = props.tableList
      options.value.page = -1
      options.value.itemsPerPage = -1
      loading.value = false
    })

    watch(() => props.tableList, value => {
      dataTableList.value = value
      options.value.page = -1
      options.value.itemsPerPage = -1
      loading.value = false
    })

    return {
      loading,
      check_list,
      dataTableList,
      columns,
      options,
    }
  },

}
</script>
