var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-chip',{class:_vm.color == 'success'
        ? ("v-chip-" + (_vm.$vuetify.theme.dark?'dark': 'light') + "-bg success--text")
        : _vm.color == 'error'
          ? ("v-chip-" + (_vm.$vuetify.theme.dark?'dark': 'light') + "-bg error--text")
          : _vm.color == 'gray'
            ? ("v-chip-" + (_vm.$vuetify.theme.dark?'dark': 'light') + "-bg secondary--text")
            : _vm.color == 'info'
              ? ("v-chip-" + (_vm.$vuetify.theme.dark?'dark': 'light') + "-bg info--text")
              : _vm.color == 'primary'
                ? ("v-chip-" + (_vm.$vuetify.theme.dark?'dark': 'light') + "-bg primary--text")
                : _vm.color == 'warning'
                  ? ("v-chip-" + (_vm.$vuetify.theme.dark?'dark': 'light') + "-bg warning--text")
                  : "",attrs:{"color":_vm.color,"small":""}},[_vm._v(" "+_vm._s(_vm.message)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }