var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.columns,"items":_vm.dataTableList,"options":_vm.options,"dense":"","loading":_vm.loadingData,"fixed-header":"","disable-sort":"","hide-default-footer":"","height":"611","loading-text":_vm.$t('data_loading_please_wait'),"no-data-text":_vm.loadingData?_vm.$t('data_loading_please_wait'):_vm.$t('no_information')},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s(index + 1)+" ")]}},{key:"item.order_id",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"font-weight-medium text-decoration-none",attrs:{"to":{ name: 'order-detail', params: { id: item.order_id_pri } }}},[_vm._v(" "+_vm._s(item.order_id)+" ")])]}},{key:"item.course_create",fn:function(ref){
var item = ref.item;
return [_c('ConverseDate',{attrs:{"date":item.order_create}})]}},{key:"item.course_type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.course_type == 1 ? _vm.$t('times') : item.course_type == 2 ? _vm.$t('day') :_vm.$t('set'))+" ")]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }