var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.columns,"items":_vm.dataTableList,"options":_vm.options,"dense":"","loading":_vm.loading,"fixed-header":"","disable-sort":"","hide-default-footer":"","height":"611","loading-text":_vm.$t('data_loading_please_wait'),"no-data-text":_vm.loadingData?_vm.$t('data_loading_please_wait'):_vm.$t('no_information')},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s(index + 1)+" ")]}},{key:"item.order_id",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"font-weight-medium text-decoration-none",attrs:{"to":{ name: 'order-detail', params: { id: item.order_id_pri } }}},[_vm._v(" "+_vm._s(item.order_id)+" ")])]}},{key:"item.order_create",fn:function(ref){
var item = ref.item;
return [_c('ConverseDate',{attrs:{"date":item.order_create}})]}},{key:"item.orderpay_status_id",fn:function(ref){
var item = ref.item;
return [(item.orderpay_status_id==null)?_c('CustomBlock',{attrs:{"message":_vm.$t('deposit'),"color":"secondary","icon":"mdi-cash-multiple"}}):_vm._e(),(item.orderpay_status_id == 0)?_c('CustomBlock',{attrs:{"message":_vm.$t('wait_pay'),"color":"warning","icon":"mdi-alert-outline"}}):_vm._e(),(item.orderpay_status_id == 1)?_c('CustomBlock',{attrs:{"message":_vm.$t('complete_payment'),"color":"success","icon":"mdi-check"}}):_vm._e(),(item.orderpay_status_id == 2)?_c('CustomBlock',{attrs:{"message":_vm.$t('owe'),"color":"error","icon":"mdi-credit-card-outline"}}):_vm._e()]}},{key:"item.order_totalpay",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatPrice")(item.order_totalpay))+" ")]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }