<template>
  <v-data-table
    :headers="columns"
    :items="dataTableList"
    :options.sync="options"
    dense
    :loading="loadingData"
    fixed-header
    disable-sort
    hide-default-footer
    height="611"
    :loading-text="$t('data_loading_please_wait')"
    :no-data-text="loadingData?$t('data_loading_please_wait'):$t('no_information')"
  >
    <template v-slot:[`item.id`]="{ index }">
      {{ index + 1 }}
    </template>
    <template v-slot:[`item.order_id`]="{ item }">
      <router-link
        :to="{ name: 'order-detail', params: { id: item.order_id_pri } }"
        class="font-weight-medium text-decoration-none"
      >
        {{ item.order_id }}
      </router-link>
    </template>
    <template v-slot:[`item.course_create`]="{ item }">
      <ConverseDate :date="item.order_create" />
    </template>
    <template v-slot:[`item.course_type`]="{ item }">
      {{ item.course_type == 1 ? $t('times') : item.course_type == 2 ? $t('day') :$t('set') }}
    </template>
  </v-data-table>
</template>

<script>
import { ref, onMounted, watch } from '@vue/composition-api'
import { sumdate, formatPrice } from '@/plugins/filters'
import ConverseDate from '@/components/basicComponents/ConverseDate.vue'
import { i18n } from '@/plugins/i18n'

export default {
  components: {
    ConverseDate,
  },
  filters: {
    sumdate,
    formatPrice,
  },
  props: {

    loadingData: {
      type: Boolean,
      default: false,
    },
    tableList: {
      type: Array,
      default: () => [],
    },

  },
  setup(props) {
    const loading = ref(true)

    const dataTableList = ref([])
    const options = ref({})
    const columns = ref([

      {
        text: '#', align: 'start', value: 'id', width: 30,
      },
      { text: i18n.t('receipt_code'), value: 'order_id', width: 120 },
      { text: i18n.t('date'), value: 'course_create', width: 120 },
      { text: i18n.t('code'), value: 'course_id', width: 100 },
      { text: i18n.t('course_list'), value: 'course_name', width: 250 },
      {
        text: i18n.t('amount'), value: 'count', align: 'end', width: 50,
      },
      { text: i18n.t('unit'), value: 'course_type', width: 50 },

    ])

    onMounted(() => {
      dataTableList.value = props.tableList
      options.value.page = -1
      options.value.itemsPerPage = -1
      loading.value = false
    })

    watch(() => props.tableList, value => {
      dataTableList.value = value
      options.value.page = -1
      options.value.itemsPerPage = -1
      loading.value = false
    })

    return {
      loading,
      dataTableList,
      options,
      columns,
    }
  },

}
</script>
