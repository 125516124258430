<template>
  <v-data-table
    :headers="columns"
    :items="dataTableList"
    :options.sync="options"
    dense
    :loading="loadingData"
    fixed-header
    disable-sort
    hide-default-footer
    height="611"
    :loading-text="$t('data_loading_please_wait')"
    :no-data-text="loadingData?$t('data_loading_please_wait'):$t('no_information')"
  >
    <template v-slot:[`item.id`]="{ index }">
      {{ index + 1 }}
    </template>
    <template #[`item.customer_prefix`]="{ item }">
      <span
        v-if="
          item.customer_prefix == '-' ||
            item.customer_prefix == 'null' ||
            item.customer_prefix == null ||
            item.customer_prefix == ''||
            item.customer_prefix == 'ไม่ระบุ'
        "
      >
        -
      </span>
      <span v-else>{{ item.customer_prefix }}</span>
      {{ item.customer_fname }}
      {{ item.customer_lname }}

      <span
        v-if="item.customer_nname && item.customer_nname != 'null'"
      >({{ item.customer_nname }})</span>
      <br>
      <v-chip
        v-for="tag in item.tag"
        :key="tag.customer_tag_id"
        :class="`v-chip-${$vuetify.theme.dark?'dark': 'light'}-bg warning--text me-1`"
        label
        x-small
        color="warning"
      >
        {{ tag.customer_tag_name }}
      </v-chip>
    </template>
    <template v-slot:[`item.cert_create`]="{ item }">
      <ConverseDate :date="item.cert_create" />
    </template>
  </v-data-table>
</template>

<script>
import { ref, onMounted, watch } from '@vue/composition-api'
import { sumdate, formatPrice } from '@/plugins/filters'
import ConverseDate from '@/components/basicComponents/ConverseDate.vue'
import { i18n } from '@/plugins/i18n'

export default {
  components: {
    ConverseDate,
  },
  filters: {
    sumdate,
    formatPrice,
  },
  props: {

    loadingData: {
      type: Boolean,
      default: false,
    },
    tableList: {
      type: Array,
      default: () => [],
    },

  },
  setup(props) {
    const loading = ref(true)

    const dataTableList = ref([])
    const options = ref({})
    const columns = ref([

      {
        text: '#', align: 'start', value: 'id', width: 30,
      },
      { text: i18n.t('a_number'), value: 'cert_code', width: 100 },
      { text: i18n.t('applicant'), value: 'customer_prefix', width: 180 },
      { text: i18n.t('id_card'), value: 'customer_idcard', width: 150 },
      { text: i18n.t('request_date'), value: 'cert_create', width: 120 },
      { text: i18n.t('by_doctor'), value: 'user_fullname', width: 200 },

    ])

    onMounted(() => {
      dataTableList.value = props.tableList
      options.value.page = -1
      options.value.itemsPerPage = -1
      loading.value = false
    })

    watch(() => props.tableList, value => {
      dataTableList.value = value
      options.value.page = -1
      options.value.itemsPerPage = -1
      loading.value = false
    })

    return {
      loading,
      dataTableList,
      options,
      columns,
    }
  },

}
</script>
