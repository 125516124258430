import customer from '@/api/customer/customer'
import customerDetail from '@/api/customer/customerDetail'
import customerOpd from '@/api/customer/customerOpd'
import shop from '@/api/shop'
import router from '@/router'
import {
  mdiAutoFix,
  mdiCalendar,
  mdiCheck,
  mdiFileOutline,
  mdiNeedle,
  mdiReceipt,
  mdiStethoscope,
  mdiTicketAccount,
} from '@mdi/js'
import { ref, watch, onMounted } from '@vue/composition-api'
import { injectGoogleFontLink } from '@vuetify/cli-plugin-utils'

export default function useCustomerDetail() {
  const customerTab = ref(null)
  const tabs = [
    { icon: mdiReceipt, title: 'receipt' },
    { icon: mdiNeedle, title: 'drugs_equipment' },
    { icon: mdiCheck, title: 'history_check_lab' },
    { icon: mdiTicketAccount, title: 'user_service' },
    { icon: mdiAutoFix, title: 'remaining_course' },
    { icon: mdiFileOutline, title: 'medical_certificate' },
    { icon: mdiStethoscope, title: 'opd_card_history' },
    { icon: mdiCalendar, title: 'my_appointment' },
  ]

  const customerOrderList = ref([])

  const {
    customerDetailOrder,
    customerDetailService,
    customerDetailCourse,
    customerDetailCert,
    customerDetailAppoint,
  } = customerDetail

  const customerData = ref({})
  const loadingCustomer = ref(true)
  const checkList = ref([])
  const checkLastData = ref(null)
  const drugList = ref([])
  const receiptLastDate = ref(null)
  const receiptList = ref([])
  const serviceList = ref([])
  const serviceLastDate = ref(null)
  const courseList = ref([])
  const certList = ref([])
  const opdCardList = ref([])
  const appointmentList = ref([])
  const isAddQueue = ref(false)
  const statusAddQueue = ref('1')
  const loadingTab = ref(false)

  const { customerById } = customer
  const { opdCardListByCustomer } = customerOpd
  const { shop: shopGet } = shop
  const statusShopX = ref(null)

  onMounted(async () => {
    getShopData()
  })

  const getShopData = async () => {
    try {
      const response = await shopGet()
      statusShopX.value = response.shop_x
      console.log('shopData', statusShopX.value)
    } catch (error) {
      console.log(error)
    }
  }

  watch(customerTab, value => {
    if (value === 3) {
      getCustomerDetailService()
    } else if (value === 4) {
      getCustomerDetailCourse()
    } else if (value === 5) {
      getCustomeDetailCert()
    } else if (value === 6) {
      getCustomerOpdCardList()
    } else if (value === 7) {
      getCustomerDetailAppoint()
    }
  })

  customerById(router.currentRoute.params.id).then(async res => {
    customerData.value = res
    getCustomerDetailOrder()
    // getCustomerOpdCardList()
    // getCustomerDetailService()
    // getCustomerDetailCourse()
    // getCustomeDetailCert()
    // getCustomerDetailAppoint()
    loadingCustomer.value = false
  })

  const getCustomerDetailAppoint = () => {
    loadingTab.value = true
    customerDetailAppoint({
      customer_id_pri: router.currentRoute.params.id,
      date_start: '',
      date_end: '',
    }).then(res => {
      appointmentList.value = res
      loadingTab.value = false
    })
  }

  const getCustomerOpdCardList = () => {
    loadingTab.value = true
    shopGet().then(res => {
      opdCardListByCustomer({
        customer_id_pri: router.currentRoute.params.id,
        shop_id_pri: res.shop_id_pri,
      }).then(data => {
        opdCardList.value = data
        loadingTab.value = false
      })
    })
  }

  const getCustomeDetailCert = () => {
    loadingTab.value = true
    customerDetailCert({
      customer_id_pri: router.currentRoute.params.id,
      date_start: '',
      date_end: '',
    }).then(res => {
      certList.value = res
      loadingTab.value = false
    })
  }

  const getCustomerDetailService = () => {
    loadingTab.value = true
    customerDetailService({
      customer_id_pri: router.currentRoute.params.id,
      date_start: '',
      date_end: '',
    }).then(res => {
      serviceList.value = res
      loadingTab.value = false
    })
  }

  const getCustomerDetailOrder = () => {
    customerDetailOrder({
      customer_id_pri: router.currentRoute.params.id,
      date_start: '',
      date_end: '',
    }).then(async res => {
      customerOrderList.value = res
      loadingCustomer.value = false
      await receiptProcess(res)
      await checkListProcess(res)
      await drugListProcess(res)
    })
  }

  const getCustomerDetailCourse = () => {
    loadingTab.value = true
    customerDetailCourse({
      customer_id_pri: router.currentRoute.params.id,
      date_start: '',
      date_end: '',
    }).then(async res => {
      courseList.value = res
      loadingTab.value = false
    })
  }

  const receiptProcess = res => {
    receiptLastDate.value = res[0] ? res[0].order_create : null
    receiptList.value = res
  }

  const drugListProcess = res => {
    if (res.length) {
      for (let i = 0; i < res.length; i++) {
        if (res[i].detail != null) {
          res[i].detail.forEach(element => {
            if (element.drug_id_pri != null) {
              element.receiptId = res[i].order_id
              element.order_id_pri = res[i].order_id_pri
              element.date = res[i].order_create
              drugList.value.push(element)
            }
          })
        }
      }
    } else {
      drugList.value = []
    }
  }

  const checkListProcess = res => {
    if (res.length) {
      for (let i = 0; i < res.length; i++) {
        if (res[i].detail != null) {
          res[i].detail.forEach(element => {
            checkLastData.value = res[0].order_create
            if (element.checking_id != null) {
              element.receiptId = res[i].order_id
              element.order_id_pri = res[i].order_id_pri
              element.date = res[i].order_create
              checkList.value.push(element)
            }
          })
        }
      }
    } else {
      checkList.value = []
      checkLastData.value = null
    }
  }

  const createOrder = () => {
    router.push({
      name: 'CreateReceipt',
      params: { id: router.currentRoute.params.id },
    })
  }

  return {
    customerTab,
    tabs,
    loadingCustomer,
    customerData,
    customerOrderList,
    isAddQueue,
    receiptLastDate,
    receiptList,
    loadingTab,

    drugList,

    checkList,

    checkLastData,

    courseList,

    serviceList,
    serviceLastDate,

    certList,

    opdCardList,
    statusShopX,
    appointmentList,
    statusAddQueue,
    createOrder,
  }
}
