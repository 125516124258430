var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.columns,"items":_vm.dataTableList,"options":_vm.options,"dense":"","loading":_vm.loading,"fixed-header":"","disable-sort":"","hide-default-footer":"","height":"611","loading-text":_vm.$t('data_loading_please_wait'),"no-data-text":_vm.loadingData?_vm.$t('data_loading_please_wait'):_vm.$t('no_information')},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s(index + 1)+" ")]}},{key:"item.receiptId",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"font-weight-medium text-decoration-none",attrs:{"to":{ name: 'order-detail', params: { id: item.order_id_pri } }}},[_vm._v(" "+_vm._s(item.receiptId)+" ")])]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('ConverseDate',{attrs:{"date":item.date}})]}},{key:"item.times",fn:function(ref){return [_vm._v(" "+_vm._s(_vm.$t("times"))+" ")]}},{key:"item.orderdetail_total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatPrice")(item.orderdetail_total))+" ")]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }