<template>
  <div id="user-view">
    <v-row>
      <v-col cols="12" md="5" lg="4" xl="3">
        <CustomerDetailBlock
          :customer-data="customerData"
          :customer-loading="loadingCustomer"
          :receipt-last-date="receiptLastDate"
          :check-last-date="checkLastData"
          :service-last-date="serviceLastDate"
        />
      </v-col>
      <v-col cols="12" md="7" lg="8" xl="9">
        <div class="d-flex justify-end">
          <h3 class="pt-2">
            {{ $t('data_use_services') }}
          </h3>
          <v-spacer></v-spacer>
          <template v-if="!loadingCustomer">
            <v-btn
              outlined
              color="success"
              :disabled="statusShopX == 1"
              class="me-2 d-none d-md-block"
              @click="
                statusAddQueue = '1'
                isAddQueue = true
              "
            >
              {{ $t('add') }} OPD
            </v-btn>
            <v-btn
              outlined
              color="success"
              fab
              small
              :disabled="statusShopX == 1"
              class="me-2 d-block d-md-none"
              @click="
                statusAddQueue = '1'
                isAddQueue = true
              "
            >
              <v-icon>{{ icons.mdiFileAccountOutline }}</v-icon>
            </v-btn>
            <v-btn
              outlined
              color="info"
              :disabled="statusShopX == 1"
              class="me-2 d-none d-md-block"
              @click="
                statusAddQueue = '2'
                isAddQueue = true
              "
            >
              {{ $t('add_a_check_queue') }}
            </v-btn>
            <v-btn
              outlined
              color="info"
              class="me-2 d-block d-md-none"
              fab
              :disabled="statusShopX == 1"
              small
              @click="
                statusAddQueue = '2'
                isAddQueue = true
              "
            >
              <v-icon>{{ icons.mdiAccountPlusOutline }}</v-icon>
            </v-btn>
            <v-btn
              outlined
              color="primary"
              :disabled="statusShopX == 1"
              class="me-2 d-none d-md-block"
              @click="
                statusAddQueue = '3'
                isAddQueue = true
              "
            >
              {{ $t('add_a_service_queue') }}
            </v-btn>
            <v-btn
              outlined
              fab
              small
              :disabled="statusShopX == 1"
              color="primary"
              class="me-2 d-block d-md-none"
              @click="
                statusAddQueue = '3'
                isAddQueue = true
              "
            >
              <v-icon>{{ icons.mdiAccountMultiplePlusOutline }}</v-icon>
            </v-btn>
            <v-btn color="warning" class="me-2 d-none d-md-block" @click="createOrder" :disabled="statusShopX == 1">
              {{ $t('open_receipt') }}
            </v-btn>
            <v-btn
              color="warning"
              class="me-2 d-block d-md-none"
              fab
              small
              @click="createOrder"
              :disabled="statusShopX == 1"
            >
              <v-icon>{{ icons.mdiReceipt }}</v-icon>
            </v-btn>
          </template>
        </div>
        <v-divider class="mt-2"></v-divider>
        <v-tabs v-model="customerTab" show-arrows class="user-tabs">
          <v-tab v-for="tab in tabs" :key="tab.icon">
            <v-icon size="20" class="me-1">
              {{ tab.icon }}
            </v-icon>
            <span>{{ $t(tab.title) }}</span>
          </v-tab>
        </v-tabs>

        <v-tabs-items
          id="user-tabs-content"
          v-model="customerTab"
          style="box-shadow: 0 2px 10px 0 rgb(94 86 105 / 10%)"
        >
          <v-tab-item transition="none">
            <ReceiptList :loading-data="loadingCustomer" :table-list="receiptList" />
          </v-tab-item>
          <v-tab-item transition="none">
            <DrugEquipmentHistory :loading-data="loadingCustomer" :table-list="drugList" />
          </v-tab-item>
          <v-tab-item transition="none">
            <CheckAndLabHistory :loading-data="loadingCustomer" :table-list="checkList" />
          </v-tab-item>
          <v-tab-item transition="none">
            <ServiceUsage :loading-data="loadingTab" :table-list="serviceList" />
          </v-tab-item>
          <v-tab-item transition="none">
            <CourseBalance :loading-data="loadingTab" :table-list="courseList" />
          </v-tab-item>
          <v-tab-item transition="none">
            <CertPaper :loading-data="loadingTab" :table-list="certList" />
          </v-tab-item>
          <v-tab-item transition="none">
            <OPDHistory :loading-data="loadingTab" :table-list="opdCardList" />
          </v-tab-item>
          <v-tab-item transition="none">
            <Appointment :loading-data="loadingTab" :table-list="appointmentList" />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
    <AddQueue v-model="isAddQueue" :status-id="statusAddQueue" />
  </div>
</template>

<script>
import {
  mdiPlus,
  mdiReceipt,
  mdiAccountPlusOutline,
  mdiAccountMultiplePlusOutline,
  mdiFileAccountOutline,
} from '@mdi/js'
import uesCustomerDetail from './useCustomerDetail'
import CustomerDetailBlock from '@/components/basicComponents/CustomerDetailBlock.vue'
import DrugEquipmentHistory from './customerDetail/DrugEquipmentHistory.vue'
import CheckAndLabHistory from './customerDetail/CheckAndLabHistory.vue'
import ServiceUsage from './customerDetail/ServiceUsage.vue'
import CourseBalance from './customerDetail/CourseBalance.vue'
import CertPaper from './customerDetail/CertPaper.vue'
import OPDHistory from './customerDetail/OPDHistory.vue'
import Appointment from './customerDetail/Appointment.vue'
import ReceiptList from './customerDetail/ReceiptList.vue'
import AddQueue from './AddQueue.vue'

export default {
  components: {
    CustomerDetailBlock,
    DrugEquipmentHistory,
    CheckAndLabHistory,
    ServiceUsage,
    CourseBalance,
    CertPaper,
    OPDHistory,
    Appointment,
    ReceiptList,
    AddQueue,
  },
  setup() {
    const {
      customerTab,
      tabs,
      loadingCustomer,
      customerData,
      customerOrderList,
      getCheckLastDate,

      receiptLastDate,
      receiptList,
      isAddQueue,
      drugList,

      checkList,
      checkLastData,
      loadingTab,

      serviceList,
      serviceLastDate,

      courseList,

      certList,
      statusShopX,
      opdCardList,
      statusAddQueue,
      appointmentList,
      createOrder,
    } = uesCustomerDetail()

    return {
      createOrder,
      customerTab,
      tabs,
      loadingCustomer,
      customerOrderList,
      loadingTab,
      customerData,
      getCheckLastDate,
      isAddQueue,
      receiptLastDate,
      receiptList,

      drugList,

      checkList,
      checkLastData,

      serviceList,
      serviceLastDate,
      statusShopX,
      courseList,

      certList,

      opdCardList,
      statusAddQueue,
      appointmentList,
      icons: {
        mdiPlus,
        mdiReceipt,
        mdiAccountPlusOutline,
        mdiAccountMultiplePlusOutline,
        mdiFileAccountOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
