<template>
  <v-data-table
    :headers="columns"
    :items="dataTableList"
    :options.sync="options"
    dense
    :loading="loading"
    fixed-header
    disable-sort
    hide-default-footer
    height="611"
    :loading-text="$t('data_loading_please_wait')"
    :no-data-text="loadingData?$t('data_loading_please_wait'):$t('no_information')"
  >
    <template v-slot:[`item.id`]="{ index }">
      {{ index + 1 }}
    </template>
    <template v-slot:[`item.receiptId`]="{ item }">
      <router-link
        :to="{ name: 'order-detail', params: { id: item.order_id_pri } }"
        class="font-weight-medium text-decoration-none"
      >
        {{ item.receiptId }}
      </router-link>
    </template>
    <template v-slot:[`item.date`]="{ item }">
      <ConverseDate :date="item.date" />
    </template>
    <template v-slot:[`item.times`]="{ }">
      {{ $t("times") }}
    </template>
    <template v-slot:[`item.orderdetail_total`]="{ item }">
      {{ item.orderdetail_total | formatPrice }}
    </template>
  </v-data-table>
</template>

<script>
import { ref, onMounted } from '@vue/composition-api'
import { sumdate, formatPrice } from '@/plugins/filters'
import ConverseDate from '@/components/basicComponents/ConverseDate.vue'
import { i18n } from '@/plugins/i18n'

export default {
  components: {
    ConverseDate,
  },
  filters: {
    sumdate,
    formatPrice,
  },
  props: {

    loadingData: {
      type: Boolean,
      default: false,
    },
    tableList: {
      type: Array,
      default: () => [],
    },
  },

  setup(props) {
    const loading = ref(true)
    const check_list = ref([])

    const dataTableList = ref([])
    const options = ref({})
    const columns = ref([
      {
        text: '#', align: 'start', value: 'id', width: 30,
      },
      { text: i18n.t('receipt_code'), value: 'receiptId', width: 120 },
      { text: i18n.t('date'), value: 'date', width: 120 },
      { text: i18n.t('code'), value: 'checking_code', width: 100 },
      { text: i18n.t('list'), value: 'orderdetail_name', width: 200 },
      {
        text: i18n.t('amount'), value: 'orderdetail_amount', width: 30, align: 'end',
      },
      { text: i18n.t('unit'), value: 'times', width: 30 },
      {
        text: i18n.t('price'), value: 'orderdetail_total', align: 'end', width: 100,
      },
    ])

    onMounted(() => {
      dataTableList.value = props.tableList
      options.value.page = 1
      options.value.itemsPerPage = -1
      loading.value = false
    })

    return {
      loading,
      check_list,
      dataTableList,
      columns,
      options,
    }
  },

}
</script>
