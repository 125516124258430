var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.columns,"items":_vm.dataTableList,"options":_vm.options,"dense":"","loading":_vm.loadingData,"fixed-header":"","disable-sort":"","hide-default-footer":"","height":"611","loading-text":_vm.$t('data_loading_please_wait'),"no-data-text":_vm.loadingData?_vm.$t('data_loading_please_wait'):_vm.$t('no_information')},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s(index + 1)+" ")]}},{key:"item.customer_prefix",fn:function(ref){
var item = ref.item;
return [(
        item.customer_prefix == '-' ||
          item.customer_prefix == 'null' ||
          item.customer_prefix == null ||
          item.customer_prefix == ''||
          item.customer_prefix == 'ไม่ระบุ'
      )?_c('span',[_vm._v(" - ")]):_c('span',[_vm._v(_vm._s(item.customer_prefix))]),_vm._v(" "+_vm._s(item.customer_fname)+" "+_vm._s(item.customer_lname)+" "),(item.customer_nname && item.customer_nname != 'null')?_c('span',[_vm._v("("+_vm._s(item.customer_nname)+")")]):_vm._e(),_c('br'),_vm._l((item.tag),function(tag){return _c('v-chip',{key:tag.customer_tag_id,class:("v-chip-" + (_vm.$vuetify.theme.dark?'dark': 'light') + "-bg warning--text me-1"),attrs:{"label":"","x-small":"","color":"warning"}},[_vm._v(" "+_vm._s(tag.customer_tag_name)+" ")])})]}},{key:"item.cert_create",fn:function(ref){
      var item = ref.item;
return [_c('ConverseDate',{attrs:{"date":item.cert_create}})]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }