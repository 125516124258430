var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.columns,"items":_vm.dataTableList,"options":_vm.options,"dense":"","loading":_vm.loadingData,"fixed-header":"","disable-sort":"","hide-default-footer":"","height":"611","loading-text":_vm.$t('data_loading_please_wait'),"no-data-text":_vm.loadingData?_vm.$t('data_loading_please_wait'):_vm.$t('no_information')},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.appoint_id_pri",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s(index + 1)+" ")]}},{key:"item.appoint_date",fn:function(ref){
var item = ref.item;
return [_c('ConverseDate',{attrs:{"date":item.appoint_date}})]}},{key:"item.appoint_status_id",fn:function(ref){
var item = ref.item;
return [_c('AppointmentStatus',{attrs:{"type":+item.appoint_status_id}})]}},{key:"item.appoint_status_line",fn:function(ref){
var item = ref.item;
return [_c('AppointmentLineStatus',{attrs:{"status":+item.appoint_status_line}}),_vm._v(" / "),_c('AppointmentSmsStatus',{attrs:{"status":+item.appoint_status_sms}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"color":"#212121","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(!item.appoint_gid)?_c('v-btn',_vm._g(_vm._b({staticClass:"rounded-pill",attrs:{"x-small":"","outlined":"","color":"error","disabled":item.appoint_syn_id == '0'}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiGooglePlus))])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("connect_google_calendar")))])]),_c('v-tooltip',{attrs:{"color":"#212121","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.appoint_gid)?_c('v-btn',_vm._g(_vm._b({staticClass:"rounded-pill",attrs:{"x-small":"","color":"error","disabled":item.appoint_syn_id == '0'}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiGooglePlus))])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("connect_google_calendar")))])]),_c('v-tooltip',{attrs:{"color":"#212121","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"success"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiCheck))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("manage")))])]),_c('v-tooltip',{attrs:{"color":"#212121","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"info"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiEyeOutline))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("detail")))])]),_c('v-tooltip',{attrs:{"color":"#212121","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"warning"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiAlertOutline))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("pending")))])]),_c('v-tooltip',{attrs:{"color":"#212121","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiPrinter))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("print")))])]),_c('v-tooltip',{attrs:{"color":"#212121","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"error"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDeleteOutline))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("cancel")))])])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }