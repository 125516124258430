'<template>
  <div>
    <v-chip
      :color="color"
      :class="
        color == 'success'
          ? `v-chip-${$vuetify.theme.dark?'dark': 'light'}-bg success--text`
          : color == 'error'
            ? `v-chip-${$vuetify.theme.dark?'dark': 'light'}-bg error--text`
            : color == 'gray'
              ? `v-chip-${$vuetify.theme.dark?'dark': 'light'}-bg secondary--text`
              : color == 'info'
                ? `v-chip-${$vuetify.theme.dark?'dark': 'light'}-bg info--text`
                : color == 'primary'
                  ? `v-chip-${$vuetify.theme.dark?'dark': 'light'}-bg primary--text`
                  : color == 'warning'
                    ? `v-chip-${$vuetify.theme.dark?'dark': 'light'}-bg warning--text`
                    : ``
      "
      small
    >
      {{ message }}
    </v-chip>
  </div>
</template>

<script>
export default {
  props: { color: String, message: String, icon: String },
}
</script>
