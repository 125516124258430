<template>
  <v-data-table
    :headers='columns'
    :items='dataTableList'
    :options.sync='options'
    dense
    :loading='loadingData'
    fixed-header
    disable-sort
    hide-default-footer
    height='611'
    :loading-text="$t('data_loading_please_wait')"
    :no-data-text="loadingData?$t('data_loading_please_wait'):$t('no_information')"
  >
    <template v-slot:[`item.id`]='{ index }'>
      {{ index + 1 }}
    </template>
    <template v-slot:[`item.order_id`]='{ item }'>
      <router-link
        :to="{ name: 'order-detail', params: { id: item.order_id_pri } }"
        class='font-weight-medium text-decoration-none'
      >
        {{ item.order_id }}
      </router-link>
    </template>
    <template v-slot:[`item.serve_code`]='{ item }'>
      <router-link
        class='font-weight-medium text-decoration-none'
        :to="{name:'showDetailService', params:{id:item.serve_id}}"
      >
        {{ item.serve_code }}
      </router-link>
    </template>
    <template v-slot:[`item.serving_date`]='{ item }'>
      <ConverseDate :date='item.serving_date' />
    </template>
    <template v-slot:[`item.course_type`]='{ }'>
      {{ $t('times') }}
    </template>
  </v-data-table>
</template>

<script>
import { ref, onMounted,watch } from '@vue/composition-api'
import { sumdate, formatPrice } from '@/plugins/filters'
import ConverseDate from '@/components/basicComponents/ConverseDate.vue'
import { i18n } from '@/plugins/i18n'

export default {
  components: {
    ConverseDate,
  },
  filters: {
    sumdate,
    formatPrice,
  },
  props: {
    loadingData: {
      type: Boolean,
      default: false,
    },
    tableList: {
      type: Array,
      default: () => [],
    },

  },
  setup(props) {
    const loading = ref(true)
    const dataTableList = ref([])
    const options = ref({})
    const columns = ref([

      {
        text: '#', align: 'start', value: 'id', width: 30,
      },
      { text: i18n.t('receipt_code'), value: 'order_id', width: 100 },
      { text: i18n.t('by_doctor'), value: 'user_fullname', width: 150 },
      { text: i18n.t('date'), value: 'serving_date', width: 150 },
      { text: i18n.t('service_user_number'), value: 'serve_code', width: 100 },
      { text: i18n.t('course_list'), value: 'course_name', width: 250 },
      {
        text: i18n.t('amount'), value: 'count', align: 'end', width: 50,
      },
      { text: i18n.t('unit'), value: 'course_type', width: 50 },

    ])

    watch(() => props.tableList, (val) => {
      dataTableList.value = props.tableList
      options.value.page = -1
      options.value.itemsPerPage = -1
      loading.value = false
    })

    onMounted(() => {
      dataTableList.value = props.tableList
      options.value.page = -1
      options.value.itemsPerPage = -1
      loading.value = false
    })

    return {
      loading,
      dataTableList,
      options,
      columns,
    }
  },

}
</script>
